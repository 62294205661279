import React, { useRef } from 'react';
import './App.css';
import FirstImage from './assets/first.jpg';
import SecondImage from './assets/second.jpg';
import Logo from "./assets/Logo.jpg";


import { Box, Divider, Typography } from '@mui/material';
import AppBar from "@mui/material/AppBar";
import CssBaseline from "@mui/material/CssBaseline";
import Drawer from "@mui/material/Drawer";
import IconButton from "@mui/material/IconButton";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import MenuIcon from "@mui/icons-material/Menu";
import Toolbar from "@mui/material/Toolbar";

const drawerWidth = 240;

function App(props) {

  // const { window } = props;
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const aboutRef = useRef(null);
  const courseRef = useRef(null);
  const facultyRef = useRef(null);
  const testimonialRef = useRef(null);
  const touchRef = useRef(null);

  const handleDrawerToggle = () => {
    setMobileOpen((prevState) => !prevState);
  };

  const drawer = (
    <Box onClick={handleDrawerToggle} sx={{ textAlign: "center", my: 4  }}>
      <List>
        <ListItem disablePadding>
          <ListItemButton sx={{ textAlign: "center" }} onClick={() => handleScroll(aboutRef, 1)}>
            <ListItemText primary={"About Us"} />
          </ListItemButton>
        </ListItem>
        <ListItem disablePadding>
          <ListItemButton sx={{ textAlign: "center" }} onClick={() => handleScroll(courseRef, 2)}>
            <ListItemText primary={"Courses Offered"} />
          </ListItemButton>
        </ListItem>
        <ListItem disablePadding>
          <ListItemButton sx={{ textAlign: "center" }} onClick={() => handleScroll(facultyRef, 3)}>
            <ListItemText primary={"Faculty"} />
          </ListItemButton>
        </ListItem>
        <ListItem disablePadding>
          <ListItemButton sx={{ textAlign: "center" }} onClick={() => handleScroll(testimonialRef, 4)}>
            <ListItemText primary={"Testimonials"} />
          </ListItemButton>
        </ListItem>
        <ListItem disablePadding>
          <ListItemButton sx={{ textAlign: "center" }} onClick={() => handleScroll(touchRef, 5)}>
            <ListItemText primary={"Get in touch"} />
          </ListItemButton>
        </ListItem>
      </List>
    </Box>
  );

  // const container =
  //   window !== undefined ? () => window().document.body : undefined;

  const handleScroll = (elementRef, index) => {
    window.scrollTo({
      top: elementRef.current.offsetTop,
      behavior: "smooth",
    });

  };


  return (
    <>

      {/* landing page */}

      <Box sx={{ color: "black" }} >
        <CssBaseline />
        <AppBar
          component="nav"
          sx={{ backgroundColor: "white", boxShadow: "none", position: "initial" }}
        >
          <Toolbar>

            <Box
              sx={{ width: "100%", display: "flex", marginX: "3rem", my: "1rem", alignItems: "center" }}
            >
              <Box
                component="div"
                sx={{ flexGrow: 1, color: "black", width: "10%" }}
              >
                <img src={Logo} alt="" style={{ width: "10%", height: "60px" }} />
              </Box>
              

              <IconButton
                color="inherit"
                aria-label="open drawer"
                edge="start"
                onClick={handleDrawerToggle}
                sx={{ mr: 2, display: { sm: "block", md: 'none' }, color: "black" }}
              >
                <MenuIcon />
              </IconButton>
              <Box sx={{ display: { xs: "none", sm: "none", md: "flex" }, }}>
                <Box sx={{ mx: "10px", color: "black", cursor: "pointer", px: "5px" }} onClick={() => handleScroll(aboutRef, 1)}>
                  <Typography sx={{ fontSize: "16px" }}>About Us</Typography>
                </Box>
                <Box sx={{ mx: "10px", color: "black", cursor: "pointer", px: "5px" }} onClick={() => handleScroll(courseRef, 2)}>
                  <Typography sx={{ fontSize: "16px" }}>Courses Offered</Typography>
                </Box>
                <Box sx={{ mx: "10px", color: "black", cursor: "pointer", px: "5px" }} onClick={() => handleScroll(facultyRef, 3)}>
                  <Typography sx={{ fontSize: "16px" }}>Faculty</Typography>
                </Box>
                <Box sx={{ mx: "10px", color: "black", cursor: "pointer", px: "5px" }} onClick={() => handleScroll(testimonialRef, 4)}>
                  <Typography sx={{ fontSize: "16px" }}>Testimonials</Typography>
                </Box>
                <Box sx={{ mx: "10px", color: "black", cursor: "pointer", px: "5px" }} onClick={() => handleScroll(touchRef, 5)}>
                  <Typography sx={{ fontSize: "16px" }}>Get in touch</Typography>
                </Box>
              </Box>
            </Box>
          </Toolbar>
        </AppBar>
        <nav>
          <Drawer
            // container={container}
            variant="temporary"
            open={mobileOpen}
            onClose={handleDrawerToggle}
            ModalProps={{
              keepMounted: true, // Better open performance on mobile.
            }}
            sx={{
              display: { sm: "block", md: "none" },
              "& .MuiDrawer-paper": {
                boxSizing: "border-box",
                width: drawerWidth,
              },
            }}
          >
            {drawer}
          </Drawer>
        </nav>

        {/* hero-section */}

        <Box component="main" sx={{ p: 3, backgroundColor: "#ECF4F8" }} ref={aboutRef}>



          <div className="hero-section">

            {/* left side text */}

            <div className="hero-left-child">
              <Box >
                {/* <Typography className='title'>Welcome to Lincoln - 1</Typography> */}
                <Typography className="subtitle">Manthan Chemistry Classes{" "}</Typography>
                <Divider sx={{ width: "20%", backgroundColor: "#086ED4", height: "4px", }} />
                <Typography sx={{ fontSize: "20px", fontWeight: "400", mt: "2rem", mb: "1rem" }}>Welcome to Manthan Chemistry Classes - where the fascination for chemistry transforms into academic excellence. We provide a dynamic learning environment that not only demystifies complex concepts but also fosters a love for the subject. Nurturing a profound understanding of the molecular world, we take pride in offering top-notch chemistry tuition classes.</Typography>

              </Box>
            </div>

            {/* Right side image */}
            <div className="hero-right-child">
              <img src={FirstImage} alt="" />
            </div>
          </div>
        </Box>
      </Box>

      {/* Course Offered */}

      <div className='course-section-main' ref={courseRef} >

        <div className='cousre-section'>
          <Typography className='title' >Courses Offered</Typography>
          <Divider sx={{ width: "20%", backgroundColor: "#086ED4", height: "4px", mt: "1rem", mb: "3rem" }} />
          <div className='content'>


            <div className='paper1'>
              <Typography sx={{ fontSize: "25px", fontWeight: "700", mb: "1rem" }}>For XI & XII classes </Typography>
              <Typography sx={{ fontSize: "20px" }}>Delve into the world of chemistry with our specialized classes tailored for 11th and 12th class students, designed to unravel the intricacies of this fascinating subject.</Typography>
            </div>
            <div className='paper2' >
              <Typography sx={{ fontSize: "25px", fontWeight: "700", mb: "1rem" }}>For X class </Typography>
              <Typography sx={{ fontSize: "20px" }}>We extend our expertise to 10th class students with dedicated Science classes, providing a comprehensive foundation for their academic pursuits.</Typography>
            </div>

          </div>

        </div>


      </div>

      {/* For Faculty */}

      <div className='faculty-section-main' ref={facultyRef} >

        <div className='faculty-section'  >
          {/* left box */}
          <div className="faculty-left-child">
            <img src={SecondImage} alt="" style={{ width: "100%" }} />

          </div>
          {/* right box */}
          <div className='faculty-right-child'>
            <Typography className='title' >Faculty</Typography>
            <Divider sx={{ width: "20%", backgroundColor: "#086ED4", height: "4px", mt: "1rem" }} />
            <Typography sx={{ fontSize: "20px", fontWeight: "400", mt: "2rem", mb: "1rem" }}>
              <div style={{ marginBottom: "1rem", backgroundColor: "#ECF4F8", padding: "1rem", borderRadius: "10px" }}>
                More than 20 years’ experience teaching Chemistry and science in Noida area - With an illustrious track record spanning two decades, the teacher has consistently demonstrated exceptional expertise, cultivating a legacy of academic excellence and student success.
              </div>
              <div style={{ marginBottom: "1rem", padding: "0rem 1rem" }}>
                Her enthusiasm for Chemistry sparks a genuine interest in even the most reluctant students.

              </div>

              <div style={{ marginBottom: "1rem", backgroundColor: "#ECF4F8", padding: "1rem", borderRadius: "10px" }}>
                She takes the time to understand each student's unique learning style and tailors her instruction accordingly.

              </div>
              <div style={{ marginBottom: "1rem", padding: "0rem 1rem" }}>
                Regular tests and assignments contribute to the continual improvement of students, fostering enhanced academic performance and overall development.
              </div>

              <div style={{ marginBottom: "1rem", backgroundColor: "#ECF4F8", padding: "1rem", borderRadius: "10px" }}>
                Beyond the Classroom – Her impact extends beyond the classroom walls. She serves as a mentor, guiding students not just academically but also personally.
              </div>


            </Typography>
          </div>

        </div>

      </div>

      {/* Testimonials */}

      <div className="testimonial-section" ref={testimonialRef}>

        <Typography className='title'>Testimonials</Typography>
        <Divider sx={{ width: "10%", backgroundColor: "#086ED4", height: "4px", mb: "4rem" }} />
        <div className="content" >
          <div className="content-card1">
            <Box sx={{ display: "flex", flexDirection: "column", justifyContent: "space-between" }}>

              <Typography>Her passion for the subject is contagious, and she makes even the most challenging topics feel accessible. She has a unique way of breaking down difficult topics into manageable pieces, making it so much easier for us to comprehend.</Typography>
              <Typography sx={{ mt: "2rem", fontWeight: "600", }}>- Dhruv Gupta</Typography>


            </Box>
          </div>
          <Box className="content-card2">
            <Box sx={{ display: "flex", flexDirection: "column", justifyContent: "space-between" }} >

              <Typography>She goes above and beyond to ensure that every student understands the concepts thoroughly. Her teaching style is engaging, and she always encourages us to ask questions.</Typography>
              <Typography sx={{ mt: "2rem", fontWeight: "600", }}>- Shalini</Typography>

            </Box>


          </Box>

          <Box className="content-card3">
            <Box sx={{ display: "flex", flexDirection: "column", justifyContent: "space-between" }} >

              <Typography>Ma’am’s real-life examples and experiments bring the subject to life, making it easier for us to grasp complex concepts. She's patient, approachable, and always willing to help. she genuinely cares about each student's success.</Typography>
              <Typography sx={{ mt: "2rem", fontWeight: "600", }}>- Ankit Sharma</Typography>


            </Box>


          </Box>

        </div>


      </div>

      {/* get in touch */}
      <div className='footer-main' ref={touchRef}>
        <div style={{ margin: "auto" }} className='footer-section' >

          <Typography sx={{ fontWeight: "700", fontSize: "35px", pb: "2rem", color: "white", textAlign: "center" }}>Get in touch</Typography>
          <Divider sx={{ width: "10%", backgroundColor: "#086ED4", height: "4px", mb: "4rem", mx: "auto" }} />

          <Typography sx={{ color: "white", textAlign: "center" }}>
            Actively serving Sector 82, Sector 137, Sector 93 and other surrounding areas of Noida since 2005.
            
            <div>Ph. 98739-05253</div>
          </Typography>
          

          <Box sx={{ display: "flex", justifyContent: "center", mt: "2rem" }}>
            <a href="tel:+91-9910450249" style={{ textDecoration: "none", backgroundColor: "transparent", fontSize: "20px", fontWeight: "500", border: "3px solid white", padding: ".48rem 1rem", color: "white" }}>Contact us</a>
          </Box>


        </div>

      </div>


    </>
  );
}

export default App;
